import React, { useEffect } from "react";

import { useState } from "react";
import { useUrlContext } from '../context/urlContext';
//import BasicTable from "../Components/BasicReactTable";

import ParentComponent from "../Components/ParentForTableAndFormComponent";
import axios from "axios";
import PartnerForm from "../Forms/PartnerForm";


function Partner(props) {
    const { UrlHelperObj } = useUrlContext();
    const [columnInfo, setColumnInfo] = useState([
        {  title: 'Name','dbname':'name' },
        {title:'WholeSale brand Name',dbname:'parent_wholesale_brand'},
        {title:'Retailer brand Name',dbname:'retailer_brand_name'},
       {title:'Location',dbname:'location'},
       {title:'Contacts',dbname:'key_contacts'},
       {title:'Logo',dbname:'logo'}
      
    ]);
    const[partnerRecords,setPartnerRecords]=useState([]);
    
    

    async function getPartnerRecords()
    {
        await axios.get(UrlHelperObj.partnerUrl())
        .then(response => response.data)
        .then(results => setPartnerRecords(results))
        .catch(err => console.log(err));
    }
    async function deletePartnerRecord(record)
    {
        await axios.delete(UrlHelperObj.partnerUrl(record))
        .then(response => response.data)
        .then(results =>console.log("delete of Partner "+record+" successful"))
        .catch(err => console.log(err));
        getPartnerRecords();
    }
    async function editPartnerRecord(record)
    {
        // const data = {
        //     id:record.id,
        //     name:record.partnerName,
        //     parent_wholesale_brand:record.partnerWholesaleBrandName,
        //     retailer_brand_name:record.retailerBrandName,
        //     location:record.location,
        //     key_contacts:record.contact,
        //     logo:record.logo
        // };
        const data = new FormData();
        data.append("id",record.id);
        data.append("name",record.partnerName);
        data.append("parent_wholesale_brand",record.partnerWholesaleBrandName);
        data.append("retailer_brand_name",record.retailerBrandName)
        data.append("location",record.location);
        data.append("key_contacts",record.contact);
        data.append("image",record.image);

        console.log("Partner edit"+JSON.stringify(record));
        await axios.put(UrlHelperObj.partnerUrl(),data,{headers: {
            'Content-Type': 'multipart/form-data'
         }})
        .then(response => response.data)
        .then(results =>console.log("Edit of Partner "+record+" successful"))
        .catch(err => console.log(err));
        getPartnerRecords();
    }
    async function addPartnerRecord(record)
    {
           //     name:record.partnerName,
        //     parent_wholesale_brand:record.partnerWholesaleBrandName,
        //     retailer_brand_name:record.retailerBrandName,
        //     location:record.location,
        //     key_contacts:record.contact,
        //     image:record.image
        // };

        const data = new FormData();
        data.append("id",0);
        data.append("name",record.partnerName);
        data.append("parent_wholesale_brand",record.partnerWholesaleBrandName);
        data.append("retailer_brand_name",record.retailerBrandName)
        data.append("location",record.location);
        data.append("key_contacts",record.contact);
        data.append("image",record.image);
        //data.append("logo",record.image.name)
     
        console.log("ADD"+data.image);
        await axios.post(UrlHelperObj.partnerUrl(),data,{headers: {
            'Content-Type': 'multipart/form-data'
         }})
        .then(response => response.data)
        .then(results =>console.log("Add of Partner "+record+" successful"))
        .catch(err => console.log(err));
        getPartnerRecords();
    }
    useEffect(() =>{
        //setTableFlag(true);
        getPartnerRecords();
    },[])

    function handleFormSubmit(data,isAddOperation,isCancelOperation)
    {
        
        console.log(data.image)
        props.setFormShown(false);
        if(isCancelOperation === true)
        {
            console.log("Cancelled operation");
            return 0;
        }
        console.log("handleFormSubmit"+data+"-add-operation-"+isAddOperation);
        if(isAddOperation === true)
            addPartnerRecord(data);
        else
            editPartnerRecord(data);
    }
    
    
    return(
    
        <ParentComponent 
        data={partnerRecords}
        columns={columnInfo}
        form={PartnerForm}
        handleFormSubmitFunc={handleFormSubmit}
        setFormShownFlag={props.setFormShown}
        alreadySelectedOwnRow={props.alreadySelectedOwnRow}
        deleteFunc={deletePartnerRecord}
        setSelectedRow={props.setSelectedRow}
        />
    );
            
}
export default Partner;