import React, { useEffect } from "react";

import { useState } from "react";
import { useUrlContext } from '../context/urlContext';
import BasicTable from "../Components/BasicReactTable";

import ParentComponent from "../Components/ParentForTableAndFormComponent";
import axios from "axios";
import BrandForm from "../Forms/BrandForm";

function Brand(props) {
    const { UrlHelperObj } = useUrlContext();
    const[selectedIP,setSelectedIP] = useState(null);
    const [columnInfo, setColumnInfo] = useState([
        {  title: 'Name',dbname:'name' },
        {title:'Issuing Partner',dbname:'issuer_name'},
     
      
    ]);
    const[brandRecords,setBrandRecords]=useState([]);
    
    
    useEffect(()=>{setSelectedIP(props.IssuingPartnerRow);
    },[props.IssuingPartnerRow]);

    useEffect(()=>{getBrandRecords()},[selectedIP]);

    async function getBrandRecords()
    {
    
        await axios.get(UrlHelperObj.brandUrl_GET(selectedIP.id))
        .then(response => response.data)
        .then(results => setBrandRecords(results))
        .catch(err => console.log(err));
    }
    async function deleteBrandRecord(record)
    {
        await axios.delete(UrlHelperObj.brandUrl(record))
        .then(response => response.data)
        .then(results =>console.log("delete of Brand "+record+" successful"))
        .catch(err => console.log(err));
        getBrandRecords();
    }
    async function editBrandRecord(record)
    {
        const data = {
            id:record.id,
            name:record.brandName,
           
        };
        console.log("Brand edit"+JSON.stringify(record));
        await axios.put(UrlHelperObj.brandUrl(),data)
        .then(response => response.data)
        .then(results =>console.log("Edit of Brand "+record+" successful"))
        .catch(err => console.log(err));
        getBrandRecords();
    }
    async function addBrandRecord(record)
    {
           //     name:record.partnerName,
        //     parent_wholesale_brand:record.partnerWholesaleBrandName,
        //     retailer_brand_name:record.retailerBrandName,
        //     location:record.location,
        //     key_contacts:record.contact,
        //     image:record.image
        // };

        // const data = new FormData();
        // data.append("id",0);
        // data.append("name",record.brandName);
        // data.append("issuer_id",record.issuerId);
        const data={
            id:0,
            name:record.brandName,
            issuer_id:record.issuerId
        };
        
        await axios.post(UrlHelperObj.brandUrl(),data,)
        .then(response => response.data)
        .then(results =>console.log("Add of Brand "+record+" successful"))
        .catch(err => console.log(err));
        getBrandRecords();
    }
    useEffect(() =>{
        //setTableFlag(true);
        getBrandRecords();
    },[])

    function handleFormSubmit(data,isAddOptr,isCancelOptr)
    {
        
        props.setFormShown(false);
        if(isCancelOptr === true)
        {
            console.log("Cancelled optr");
            return 0;
        }
        
        if(isAddOptr === true)
            addBrandRecord(data);
        else
            editBrandRecord(data);
    }
    
    
    return(
    
        <ParentComponent 
        data={brandRecords}
        columns={columnInfo}
        form={BrandForm}
        handleFormSubmitFunc={handleFormSubmit}
        setFormShownFlag={props.setFormShown}
        deleteFunc={deleteBrandRecord}
        setSelectedRow={props.setSelectedRow}
        selectedIssuingPartnerRow={props.IssuingPartnerRow}
        alreadySelectedOwnRow={props.alreadySelectedOwnRow}
        />
    );
            
}
export default Brand;
