import React, { useState } from "react";
import {
    Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Box
  
} from "@material-ui/core";

import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles(()=>({
    link:{
        textDecoration:"none",
        color: "blue",
        fontSize: "20px",
    },
    icon:{
        color: "white"
    }
}));
function DrawerComponent(props)
{
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    const drawerWidth = 240;
    function handleDrawerToggle()
    {
        setOpenDrawer(false);
    }
    // const drawer = (
    //     <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
    //       {/* <Typography variant="h6" sx={{ my: 2 }}>
    //         MUI
    //       </Typography> */}
    //       <Divider />
    //       <List>
    //         {props.navItems.map((item) => (
    //                 <ListItem>
    //                     <ListItemText>
                            
    //                         <Link to={"/"+item} >
    //                             item
    //                         </Link>
    //                     </ListItemText>    
    //                 </ListItem>
    //             ))}
    //       </List>
    //     </Box>
    //   );
    
    return (
          <>
            <Drawer
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
            >
              <List>
              <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <Link to="/" className={classes.link}>Issuing Partner</Link>
                  </ListItemText>
                </ListItem>
                <Divider/>
                <ListItem onClick={() => setOpenDrawer(false)}>
                  <ListItemText>
                    <Link to="/redemptionPartner" className={classes.link}>Redemption Partner</Link>
                  </ListItemText>
                </ListItem>
                
              </List>
            </Drawer>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)}className={classes.icon}>
              <MenuIcon />
            </IconButton>
          </>
        );
    

}
export default DrawerComponent;