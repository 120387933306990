import React, { useEffect } from "react";

import { useState } from "react";
import { useUrlContext } from '../context/urlContext';


import ParentComponent from "../Components/ParentForTableAndFormComponent";
import axios from "axios";
import PromotionForm from "../Forms/PromotionForm";

function Promotion(props) {
    const { UrlHelperObj } = useUrlContext();
    const [columnInfo, setColumnInfo] = useState([
        {  title: 'Name',dbname:'name' },
        {title:'Brand Name',dbname:'brand_name'},
        {title:'Description',dbname:'description'},
       {title:'Launch Date/Time',dbname:'launch_date_time'},
       {title:'Valid From',dbname:'valid_from'},
       {title:'Valid To',dbname:'valid_to'},
       {title:'Valid From Time',dbname:'valid_from_time'},
       {title:'Valid To Time',dbname:'valid_to_time'},
       {title:'Total Quantity',dbname:'total_qty'},
       {title:'No.ofScans allowed',dbname:'num_scans_allowed'},
       {title:'No.ofDays allowed',dbname:'num_days_allowed'},
       {title:'Promotion Text',dbname:'promotion_text'},
       {title:'Promotion Image',dbname:'image'},
      
    ]);
    const[selectedBrand,setSelectedBrand] = useState(null);
    const[promotionRecords,setPromotionRecords]=useState([]);
    
    useEffect(()=>{setSelectedBrand(props.BrandRow)
    },[props.BrandRow]);

    useEffect(()=>{getPromotionRecords()},[selectedBrand]);


    async function getPromotionRecords()
    {
        await axios.get(UrlHelperObj.promotionUrl_GET(selectedBrand.id))
        .then(response => response.data)
        .then(results => setPromotionRecords(results))
        .catch(err => console.log(err));
    }
    async function deletePromotionRecord(record)
    {
        await axios.delete(UrlHelperObj.promotionUrl(record))
        .then(response => response.data)
        .then(results =>console.log("delete of Partner "+record+" successful"))
        .catch(err => console.log(err));
        getPromotionRecords();
    }
    async function editPromotionRecord(record)
    {
        const data = new FormData();
        data.append("id",record.id);
        data.append("name",record.name);
        data.append("brand_id",record.brand_id);
        data.append("description",record.description)
        data.append("launch_date_time",record.launch_date_time);
        data.append("valid_to",record.valid_to);
        data.append("valid_from",record.valid_from);
        data.append("valid_from_time",record.valid_from_time);
        data.append("valid_to_time",record.valid_to_time);
        data.append("num_days_allowed",record.num_days_allowed);
        data.append("num_scans_allowed",record.num_scans_allowed);
        data.append("promotion_text",record.promotion_text);
        data.append("total_qty",record.total_qty);
        data.append("programid",record.programid);
        data.append("tierid",record.tierid);
        data.append("image",record.image);
        
        await axios.put(UrlHelperObj.promotionUrl(),data,{headers: {
            'Content-Type': 'multipart/form-data'
         }})
        .then(response => response.data)
        .then(results =>console.log("Edit of Partner "+record+" successful"))
        .catch(err => console.log(err));
        getPromotionRecords();
    }
    async function addPromotionRecord(record)
    {
       
        const data = new FormData();
        data.append("id",0);
        data.append("name",record.name);
        data.append("brand_id",record.brand_id);
        data.append("description",record.description)
        data.append("launch_date_time",record.launch_date_time);
        data.append("valid_to",record.valid_to);
        data.append("valid_from",record.valid_from);
        data.append("valid_from_time",record.valid_from_time);
        data.append("valid_to_time",record.valid_to_time);
        data.append("num_days_allowed",record.num_days_allowed);
        data.append("num_scans_allowed",record.num_scans_allowed);
        data.append("promotion_text",record.promotion_text);
        data.append("total_qty",record.total_qty);
        data.append("programid",record.programid);
        data.append("tierid",record.tierid);
        data.append("image",record.image);
        //data.append("logo",record.image.name)
     
        console.log("ADD"+data.image);
        await axios.post(UrlHelperObj.promotionUrl(),data,{headers: {
            'Content-Type': 'multipart/form-data'
         }})
        .then(response => response.data)
        .then(results =>console.log("Add of Partner "+record+" successful"))
        .catch(err => console.log(err));
        getPromotionRecords();
    }
    useEffect(() =>{
        //setTableFlag(true);
        getPromotionRecords();
    },[]);

    function handleFormSubmit(data,isAddOperation,isCancelOperation)
    {
        props.setFormShown(false);
        if(isCancelOperation === true)
        {
            console.log("Cancelled operation");
            return 0;
        }
        console.log("handleFormSubmit"+data+"-add-operation-"+isAddOperation);
        if(isAddOperation === true)
            addPromotionRecord(data);
        else
            editPromotionRecord(data);
    }
    
    
    return(
    
        <ParentComponent 
        data={promotionRecords}
        columns={columnInfo}
        form={PromotionForm}
        handleFormSubmitFunc={handleFormSubmit}
        setFormShownFlag={props.setFormShown}
        alreadySelectedOwnRow={props.alreadySelectedOwnRow}
        deleteFunc={deletePromotionRecord}
        setSelectedRow={props.setSelectedRow}
        selectedBrandRow={props.BrandRow}
        />
    );
            
}
export default Promotion;
