import { Button, Grid, TextField,Paper } from '@material-ui/core';
import { useFormik } from 'formik';
import {React, useEffect, useState} from 'react'
import * as yup from 'yup';

export default function PartnerForm(props)
{
    const[imageName,setImageName] = useState(null);   
    const[isAddOperation,setIsAddOperation] = useState(false);
    const[recvValues,setRecvValues] = useState({ id:'', partnerName:'',
    partnerWholesaleBrandName:'',
    retailerBrandName:'',
    location:'',
    contact:'',
    image:''});
    useEffect(()=>{

       if(props.selectedRow !== null )//&& valueLoaded === false)
       {
            console.log("Partner Edit"+JSON.stringify(props.selectedRow));
            setRecvValues({
                id:props.selectedRow.id,
                partnerName:props.selectedRow.name,
                partnerWholesaleBrandName:props.selectedRow.parent_wholesale_brand,
                retailerBrandName:props.selectedRow.retailer_brand_name,
                location:props.selectedRow.location,
                contact:props.selectedRow.key_contacts,
                image:props.selectedRow.logo
            });
            setImageName(props.selectedRow.logo);
            setIsAddOperation(false);
        }
        else
        {
            setRecvValues({  id:'',partnerName:'',
            partnerWholesaleBrandName:'',
            retailerBrandName:'',
            location:'',
            contact:'',
            image:''});
            setIsAddOperation(true);
            setImageName("");
        }

    },[props.selectedRow]);
    
    const validationSchema = yup.object({
        partnerName: yup
            .string("AAA")
          .required('Partner is required'),
        partnerWholesaleBrandName: yup
        .string("AAA")
          .required('Wholesale brand name is required'),
        retailerBrandName: yup
        .string("AAA")
          .required("Retail brand name required"),
        location: yup
        .string("AAA")
            .required("Location is required"),
        contact:yup
        .string("AAA")
        .required("Contact is required"),
        
      });
      function onCancelForm()
      {
          //alert("Cancel");
          props.handleSubmit(null,isAddOperation,true)
      }
   
    const formik = useFormik({
        enableReinitialize:true,
        validationSchema: validationSchema,
        initialValues:{
            id:recvValues?.id || '',
            partnerName:recvValues?.partnerName || '',
            partnerWholesaleBrandName:recvValues?.partnerWholesaleBrandName || '',
            retailerBrandName:recvValues?.retailerBrandName || '',
            location:recvValues?.location || '',
            contact:recvValues?.contact || '',
            image:recvValues?.logo || ''
            // image:recvValues?.image || null,
        },

        onSubmit:(values)=>{
           console.log(values.image);
           // alert(JSON.stringify(values));
            if(values.image === "" || values.image === undefined || values.image === null)
            {
                alert("Please select Logo Image");
                return;
            }
            if(values.partnerName === '')
                props.handleSubmit(null,isAddOperation,true);
            else
            {

                props.handleSubmit(values,isAddOperation,false);
            }
           //this.resetForm();

        },
        // onReset:(values)=>{
        //     alert("Onreset");

        //     props.handleSubmit(null,isAddOperation,true)
        // }
    });

    return(
        // <Grid style={{ borderStyle: 'solid', borderColor: 'pink' }} container item spacing={3} xs={12}
        // direction="column" alignItems='flex-start' >
            // <Paper >
            <form onSubmit={formik.handleSubmit} >
                {/* <Grid container spacing={2}> */}
                    <Grid  item container xs={12} spacing={2}
                    justifyContent="space-around"  style={{ borderStyle: 'none', borderColor: 'black' }}
                    alignItems='center' direction="row">
                {/* <Grid container spacing={{xs:2,md:5}} direction="row" columns={{xs:4,sm:8,md:12}}> */}
                            <Grid item xs={12} sm={12} md={12}  >
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="partnerName"
                                    name="partnerName"
                                    label="Name"
                                    value={formik.values.partnerName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.partnerName && Boolean(formik.errors.partnerName)}
                                    helperText={formik.touched.partnerName && formik.errors.partnerName}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={5}  >
                                <TextField
                                fullWidth
                                variant='outlined'
                                    id="partnerWholesaleBrandName"
                                    name="partnerWholesaleBrandName"
                                    label="Partner wholesale brand Name"
                                    value={formik.values.partnerWholesaleBrandName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.partnerWholesaleBrandName && Boolean(formik.errors.partnerWholesaleBrandName)}
                                    helperText={formik.touched.partnerWholesaleBrandName && formik.errors.partnerWholesaleBrandName}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={5}>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="retailerBrandName1"
                                    name="retailerBrandName"
                                    label="retailer brand Name"
                                    value={formik.values.retailerBrandName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.retailerBrandName && Boolean(formik.errors.retailerBrandName)}
                                    helperText={formik.touched.retailerBrandName && formik.errors.retailerBrandName}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="partnerlocation"
                                    name="location"
                                    label="Location"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    error={formik.touched.location && Boolean(formik.errors.location)}
                                    helperText={formik.touched.location && formik.errors.location}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={5}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="partnerContact"
                                    name="contact"
                                    label="Contact"
                                    value={formik.values.contact}
                                    onChange={formik.handleChange}
                                    error={formik.touched.contact && Boolean(formik.errors.contact)}
                                    helperText={formik.touched.contact && formik.errors.contact}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={5}  >
                            <div className="form-group">
                                    <label for="file">Logo: </label>
                                    <input id="image" name="image" type="file" 
                                        accept='image/png, image/jpeg'
                                        // value={imageName}
                                        onChange={(event) => {
                                        formik.setFieldValue("image", event.currentTarget.files[0]);
                                         setImageName(event.currentTarget.files[0].name);
                                    }} className="form-control"
                                    // error={formik.touched.image && Boolean(formik.errors.image)}
                                    // helperText={formik.touched.image && formik.errors.image}
                                    />
                                    { imageName }
                                    {/* <Thumb file={values.file} /> */}
                                    </div>
                                {/* <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="image"
                                    name="image"
                                    label="Logo"
                                    type="file"
                                    // value={file}
                                    onChange={(event)=> formik.setFieldValue("image", event.currentTarget.files[0])}
                                    inputProps={{accept:"image/png, image/jpeg"}}
                                /> */}
                            </Grid>

                    </Grid>
                    <Grid container xs={12}
                     direction="row" spacing={3}
                      style={{ borderStyle: 'none', borderColor: 'black' }} justifyContent='center'>
                        <Grid item >
                            <Button type='submit' color='primary' variant='contained'> submit </Button>
                        </Grid>
                        <Grid item>
                            <Button type='button' onClick={onCancelForm}  color='secondary' variant='contained'> cancel</Button>
                        </Grid>
                    </Grid>
                {/* </Grid> */}
            </form>
            // </Paper>
        // </Grid>


    );
}