import { Toolbar,AppBar,Typography,CssBaseline, makeStyles} from "@material-ui/core";
import { useTheme,useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from './Drawer';

const useStyles = makeStyles((theme) => ({
    navlinks: {
      marginLeft: theme.spacing(5),
      display: "flex",
    },
    logo: {
      flexGrow: "1",
      cursor: "pointer",
      color: "white",
    },
    link: {
      textDecoration: "none",
      color: "white",
      fontSize: "20px",
      marginLeft: theme.spacing(20),
      "&:hover": {
        color: "yellow",
        borderBottom: "1px solid white",
      },
    },
  }));


function Navbar() {
    const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  
    return(
        <AppBar position="static">
            <CssBaseline/>
            <Toolbar>
                <Link to="/">
                  <Typography variant="h6" className={classes.logo}>
                    OmniQpon
                    </Typography></Link>
            
                {isMobile ? (
             <DrawerComponent />
                    ) : (
                <div className={classes.navlinks}>
                    <Link to="/IssuingPartner" className={classes.link}>
                      IssuingPartner
                    </Link>
                    <Link to="/RedemptionPartner" className={classes.link}>
                    RedemptionPartner
                    </Link>
                   {/* <Button variant="contained" className={classes.Button} to="/IssuingPartner">IssuingPartner</Button>
                   <Button variant="contained" className={classes.Button} to="/RedemptionPartner">RedemptionPartner</Button> */}
                </div>
                )}
          </Toolbar>
        </AppBar>
        
    );
};
export default Navbar;