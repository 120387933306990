
import './App.css';
import Navbar from './Components/Navbar';
import IssuingPartner from "./Pages/IssuingPartner";
import StartPage from './Pages/startPage';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UrlContext } from './context/urlContext';
import UrlHelper from './Components/UrlHelperClass';


let UrlHelperObj = new UrlHelper();

function App() {
  return (
    <Router>
    <UrlContext.Provider value={{ UrlHelperObj }}>
    <Navbar />
    <Switch>
      <Route exact path="/" component={StartPage} />
      <Route exact path="/IssuingPartner" component={IssuingPartner} />
    </Switch>
    </UrlContext.Provider>
  </Router>
  );
}

export default App;
