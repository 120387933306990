import { makeStyles } from '@material-ui/core/styles';
// const useStyles1 = makeStyles(theme => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1)
//   },
//   dense: {
//     marginTop: theme.spacing(2)
//   },
//   menu: {
//     width: 400
//   }
// }));

const useStyles = makeStyles(theme => ({
  paper1: {
    padding: theme.spacing(2)
  },
  paper2: { padding: theme.spacing(0) },
  appBar: {
    position: 'relative'
  },
  tableRow: {
    height: 5,
   
  },
  tableCell: {
    padding: "0px 16px"
  },
  layout: {
    width: '1000',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: 1000,
    //   marginLeft: 'auto',
    //   marginRight: 'auto'
    // }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },

  // paper: {
  //   width: 1300,
  //   marginTop: theme.spacing(3),
  //   marginBottom: theme.spacing(3),
  //   padding: theme.spacing(2)
  //   // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
  //   //   marginTop: theme.spacing(6),
  //   //   marginBottom: theme.spacing(6),
  //   //   padding: theme.spacing(3)
  //   // }
  // },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  
  // buttons: {
  //   display: 'flex',
  //   justifyContent: 'flex-end'
  // },
  button: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(1),
    backgroundColor:'blue'
    
  }
}));
export default useStyles;
