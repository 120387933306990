import { Button, Grid, TextField,MenuItem } from '@material-ui/core';

import { useFormik,Field,FieldProps } from 'formik';
import {React, useEffect, useState} from 'react'
import * as yup from 'yup';
import axios from "axios";
import { useUrlContext } from '../context/urlContext';

export default function PromotionForm(props)
{
    const { UrlHelperObj } = useUrlContext();
    const[fileName,setFileName] = useState('');
    const[programList,setProgramList] = useState([]);
    const[tierList,setTierList] = useState([]);
    const[isAddOperation,setIsAddOperation] = useState(false);
    const[recvValues,setRecvValues] = useState({ 
    id:'', name:'',
    brand_id:props.BrandRow.id,
    description:'',
    launch_date_time:'',
    valid_from:'',
    valid_to:'',
    valid_from_time:'',
    valid_to_time:'',
    total_qty:'',
    num_scans_allowed:'',
    num_days_allowed:'',
    promotion_text:'',
    programid:'',
    tierid:'',
    image:''});
    useEffect(()=>{
        getProgramList();
       if(props.selectedRow !== null )//&& valueLoaded === false)
       {
            console.log("Promotion Edit"+JSON.stringify(props.selectedRow));
            setFileName(props.selectedRow.image);
            setRecvValues({
                id:props.selectedRow.id,
                name:props.selectedRow.name,
                brand_id:props.BrandRow.id,
                description:props.selectedRow.description,
                launch_date_time:props.selectedRow.launch_date_time,
                valid_from:props.selectedRow.valid_from,
                valid_to:props.selectedRow.valid_to,
                valid_from_time:props.selectedRow.valid_from_time,
                valid_to_time:props.selectedRow.valid_to_time,
                total_qty:props.selectedRow.total_qty,
                num_scans_allowed:props.selectedRow.num_scans_allowed,
                num_days_allowed:props.selectedRow.num_days_allowed,
                promotion_text:props.selectedRow.promotion_text,
                programid:props.selectedRow.programid,
                tierid:props.selectedRow.tierid,
                image:props.selectedRow.image
            });
            setIsAddOperation(false);
        }
        else
        {
            setFileName("");
            setRecvValues({  
                id:'', name:'',
                brand_id:props.BrandRow.id,
                description:'',
                launch_date_time:'',
                valid_from:'',
                valid_to:'',
                valid_from_time:'',
                valid_to_time:'',
                total_qty:'',
                num_scans_allowed:'',
                num_days_allowed:'',
                promotion_text:'',
                programid:'',
                tierid:'',
                image:''    
            });
            setIsAddOperation(true);
        }

    },[props.selectedRow]);
    
    useEffect(()=>{
        console.log("Change in tier"+recvValues.programid);
        if(recvValues.programid !== null || recvValues.programid !== undefined || recvValues.programid !== '')
            getTierList(recvValues.programid);
    },[recvValues.programid])
    async function getProgramList()
    {
        await axios.get(UrlHelperObj.programUrl())
                    .then(res => res.data)
                    .then(results=> setProgramList(results.programs))
                    .catch(err => console.log("Error in getting program list"));
        
    }

    const handleChangeInTierId = (e) =>{
        formik.setFieldValue("tierid",e.target.value);
    }
    const handleChangeInProgramId=(e)=>
    {
        console.log((e));
        
        formik.setFieldValue("programid",e.target.value);
        getTierList(e.target.value)
    }   
    const validationSchema = yup.object({
        name: yup
            .string("AAA")
            .required('Promotion Name is required'),
        total_qty: yup
            .string("AAA")
            .required("Total quantity required"),
        num_days_allowed:yup
            .string("AAA")
            .required("num days allowed required"),
        promotion_text:yup
            .string("AAA")
            .required("Promotion text is required"),
        description:yup
            .string("AAA")
            .required("description is required"),
        valid_from:yup
            .string("AAA")
            .required("valid from date is required"),
        valid_from_time:yup
            .string("AAA")
            .required("valid from time is required"),
        valid_to:yup
            .string("AAA")
            .required("valid to date is required"),
        valid_to_time:yup
            .string("AAA")
            .required("valid to time is required"),
        num_scans_allowed:yup
            .string("AAA")
            .required("num scans allowed is required"),
        programid:yup
            .string("AAA")
            .required("Program is required"),
        tierid:yup
            .string("AAA")
            .required("tier is required"),
        launch_date_time:yup
            .string("AAA")
            .required("launch date time is required"),
        
      });
   
    
    async function getTierList(programId)
    {
        await axios.get(UrlHelperObj.tierUrl(programId))
                    .then(res => res.data)
                    .then(results=> setTierList(results.tiers))
                    .catch(err => console.log("Error in getting tier list"));

    }
    function onCancelForm()
    {
        //alert("Cancel");
        props.handleSubmit(null,isAddOperation,true)
    }
    const formik = useFormik({
        enableReinitialize:true,
        validationSchema: validationSchema,
        initialValues:{
            id:recvValues?.id || '',
            brand_id:props.BrandRow.id,
            name:recvValues?.name || '',
            launch_date_time:recvValues?.launch_date_time || '',
            description:recvValues?.description || '',
            valid_from:recvValues?.valid_from || '',
            valid_to:recvValues?.valid_to || '',
            valid_from_time:recvValues?.valid_from_time || '',
            valid_to_time:recvValues?.valid_to_time || '',
            num_days_allowed:recvValues?.num_days_allowed || '',
            num_scans_allowed:recvValues?.num_scans_allowed || '',
            promotion_text:recvValues?.promotion_text || '',
            total_qty:recvValues?.total_qty || '',
            programid:recvValues?.programid || '',
            tierid:recvValues?.tierid || '',
            image:recvValues?.image || null,
        },

        onSubmit:(values)=>{
           console.log(values.image);
            console.log("Promotion"+JSON.stringify(values));
            if(values.image === "" || values.image === undefined || values.image === null)
            {
                alert("Please select Promotion Image");
                return;
            }
            if(values.name === '')
                props.handleSubmit(null,isAddOperation,true);
            else
            {

                props.handleSubmit(values,isAddOperation,false);
            }
           //this.resetForm();

        },
        // onAbort:(values)=>{
        //     alert("abort");
        // },
        // onReset:(values)=>{
        //     alert("Onreset");

        //     props.handleSubmit(null,isAddOperation,true)
        // }
    });

    return(
        // <Grid style={{ borderStyle: 'solid', borderColor: 'pink' }} container item spacing={3} xs={12}
        // direction="column" alignItems='flex-start' >
            // <Paper >
            <form onSubmit={formik.handleSubmit}  >
                {/* <Grid container spacing={2}> */}
                    <Grid  item container xs={12} spacing={2}
                    justifyContent="space-around"  style={{ borderStyle: 'none', borderColor: 'black' }}
                    alignItems='center' direction="row">
                {/* <Grid container spacing={{xs:2,md:5}} direction="row" columns={{xs:4,sm:8,md:12}}> */}
                            <Grid item xs={12} sm={12} md={12}  >
                            </Grid>
                            <Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                fullWidth
                                variant='outlined'
                                    id="description"
                                    name="description"
                                    label="Promotion Description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="launch_date_time"
                                    name="launch_date_time"
                                    label="Launch Date and Time"
                                    value={formik.values.launch_date_time}
                                    type="datetime-local"
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ shrink: true }}  
                                    error={formik.touched.launch_date_time && Boolean(formik.errors.launch_date_time)}
                                    helperText={formik.touched.launch_date_time && formik.errors.launch_date_time}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="valid_from"
                                    name="valid_from"
                                    label="Valid from date"
                                    value={formik.values.valid_from}
                                    type="date"
                                    InputLabelProps={{ shrink: true }}  
                                    onChange={formik.handleChange}
                                    error={formik.touched.valid_from && Boolean(formik.errors.valid_from)}
                                    helperText={formik.touched.valid_from && formik.errors.valid_from}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="valid_to"
                                    name="valid_to"
                                    label="Valid To Date"
                                    value={formik.values.valid_to}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ shrink: true }}  
                                    type="date"
                                    error={formik.touched.valid_to && Boolean(formik.errors.valid_to)}
                                    helperText={formik.touched.valid_to && formik.errors.valid_to}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="valid_from_time"
                                    name="valid_from_time"
                                    label="Valid from time"
                                    value={formik.values.valid_from_time}
                                    type="time"
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ shrink: true }}  
                                    error={formik.touched.valid_from_time && Boolean(formik.errors.valid_from_time)}
                                    helperText={formik.touched.valid_from_time && formik.errors.valid_from_time}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}  >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="valid_to_time"
                                    name="valid_to_time"
                                    label="Valid To Time"
                                    value={formik.values.valid_to_time}
                                    onChange={formik.handleChange}
                                    type="time"
                                    InputLabelProps={{ shrink: true }}  
                                    error={formik.touched.valid_to_time && Boolean(formik.errors.valid_to_time)}
                                    helperText={formik.touched.valid_to_time && formik.errors.valid_to_time}
                                />
                            </Grid>
                            <Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                fullWidth
                                variant='outlined'
                                    id="num_scans_allowed"
                                    name="num_scans_allowed"
                                    label="Number of scans allowed"
                                    value={formik.values.num_scans_allowed}
                                    onChange={formik.handleChange}
                                    type="number"
                                    error={formik.touched.num_scans_allowed && Boolean(formik.errors.num_scans_allowed)}
                                    helperText={formik.touched.num_scans_allowed && formik.errors.num_scans_allowed}
                                />
                            </Grid><Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                fullWidth
                                variant='outlined'
                                    id="num_days_allowed"
                                    name="num_days_allowed"
                                    label="Number of days allowed"
                                    value={formik.values.num_days_allowed}
                                    onChange={formik.handleChange}
                                    type="number"
                                    error={formik.touched.num_days_allowed && Boolean(formik.errors.num_days_allowed)}
                                    helperText={formik.touched.num_days_allowed && formik.errors.num_days_allowed}
                                />
                            </Grid><Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                fullWidth
                                variant='outlined'
                                    id="total_qty"
                                    name="total_qty"
                                    label="Total Quantity"
                                    value={formik.values.total_qty}
                                    onChange={formik.handleChange}
                                    type="number"
                                    error={formik.touched.total_qty && Boolean(formik.errors.total_qty)}
                                    helperText={formik.touched.total_qty && formik.errors.total_qty}
                                />
                            </Grid><Grid item  xs={4} sm={4} md={4}  >
                                <TextField
                                fullWidth
                                variant='outlined'
                                    id="promotionText"
                                    name="promotion_text"
                                    label="Promotion Text"
                                    value={formik.values.promotion_text}
                                    onChange={formik.handleChange}
                                    error={formik.touched.promotion_text && Boolean(formik.errors.promotion_text)}
                                    helperText={formik.touched.promotion_text && formik.errors.promotion_text}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}  >
                            <div className="form-group">
                                    <label for="file">Promotion Image: </label>
                                    <input id="image" name="image" type="file" 
                                        // value={formik.values.image}
                                        accept='image/png, image/jpeg'
                                        onChange={(event) => {
                                            setFileName(event.currentTarget.files[0].name);
                                        formik.setFieldValue("image", event.currentTarget.files[0]);
                                    }} className="form-control"
                                    //  error={formik.touched.image && Boolean(formik.errors.image)}
                                    //  helperText={formik.touched.image && formik.errors.image}
                                    />
                                    { fileName }
                                    </div>
                                {/* <TextField
                                    fullWidth
                                    variant='outlined'
                                    id="image"
                                    name="image"
                                    label="Logo"
                                    type="file"
                                    // value={file}
                                    onChange={(event)=> formik.setFieldValue("image", event.currentTarget.files[0])}
                                    inputProps={{accept:"image/png, image/jpeg"}}
                                /> */}
                            </Grid>
                            <Grid  item xs={4} sm={4} md={4}  >
                                <TextField
                                variant='outlined'
                                fullWidth
                                select
                                id="programid"
                                name="programid"
                                label="Program"
                                value={formik.values.programid}
                                onChange={handleChangeInProgramId}
                                error={formik.touched.programid && Boolean(formik.errors.programid)}
                                helperText={formik.touched.programid && formik.errors.programid}
                                >
                                    {programList &&
                                        programList.map(type => (
                                        <MenuItem value={type.id} key={type.id}>
                                            {type.name}
                                        </MenuItem>
                                        ))}
                                </TextField>
                                
                            </Grid>
                            <Grid  item xs={4} sm={4} md={4}  >
                                <TextField
                                variant='outlined'
                                fullWidth
                                select
                                id="tierid"
                                name="tierid"
                                label="Tier"
                                value={formik.values.tierid}
                                onChange={handleChangeInTierId}
                                error={formik.touched.tierid && Boolean(formik.errors.tierid)}
                                helperText={formik.touched.tierid && formik.errors.tierid}
                                >
                                    {tierList &&
                                        tierList.map(type => (
                                        <MenuItem value={type.id} key={type.id}>
                                            {type.name}
                                        </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>

                    </Grid>
                    <Grid container xs={12}
                     direction="row" spacing={3}
                      style={{ borderStyle: 'none', borderColor: 'black' }} justifyContent='center'>
                        <Grid item >
                            <Button type='submit' color='primary' variant='contained'> submit </Button>
                        </Grid>
                        <Grid item>
                            <Button type='button' onClick={onCancelForm}  color='secondary' variant='contained'> cancel</Button>
                        </Grid>
                    </Grid>
                {/* </Grid> */}
            </form>
            // </Paper>
        // </Grid>


    );
}