import * as React from 'react';
import { Box, Typography, Grid,Paper,Button,Step,StepLabel,Stepper } from "@material-ui/core";
import { createTheme  } from "@material-ui/core/styles";
import useStyles from '../theme/formCssStyles';
import Partner from './partner';
import Brand from './brand';
import Promotion from './promotion';
import { ThemeProvider } from '@material-ui/styles';
import { useHistory } from "react-router-dom";

const steps = ['Issuing Partners', 'Brands', 'Promotions'];
// const theme = createTheme({
// components:{
//     MuiStepIcon:{
//         styleOverrides:{
//             root: {
//                 '&$completed': {
//                   color: 'pink',
//                 },
//                 '&$active': {
//                   color: 'red',
//                 },
//             active:{},
//             completed:{},
//         },
//     },
// },
// },});
const theme = createTheme({
    overrides: {
    
     MuiStepIcon: {
      root: {
        '&$completed': {
          color: 'pink',
        },
        '&$active': {
          color: 'red',
        },
      },
      active: {},
      completed: {},
    },
   
  
  
    MuiStepLabel:{
        root:{
            color:'blue'
            // '&$active': {
            //     color: 'red',
            //   },  
        },
        active:{ '&$active': {
            color: 'red',
          },
        },
        },
    },    }
);
function IssuingPartner( ) 
{
    const classes = useStyles();
    let history = useHistory();
    const [activeStep, setActiveStep] = React.useState(0);
    const [formShown,setFormShown] = React.useState(false);
    const [selectedRow,setSelectedRow] = React.useState(null);
    const [selectedPartner,setSelectedPartner] = React.useState(null);
    const [selectedBrand,setSelectedBrand] = React.useState(null);
    const [selectedPromotion,setSelectedPromotion] = React.useState(null);
    
    function handleNext() {
        console.log("handleNext"+JSON.stringify(selectedRow));
        switch(activeStep)
        {
            case 0:
                if(selectedPartner === null)
                {
                    alert("Please select Issuing partner before going for Brand");
                    return;
                }
                break;
            case 1:
                if(selectedBrand === null)
                {
                    alert("Please select Brand before going for Promotion");
                    return;
                }
                break;
            case 2:
                
                    history.push("/");
                    break;
            default:
                break;
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
    
        if (activeStep === steps.length - 1)
            console.log('next');
      }
      React.useEffect(()=>{
        //alert("selected row:"+JSON.stringify(selectedRow))
      },[selectedRow])
      function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
      }
    
      function handleReset() {
        setActiveStep(0);
        setSelectedPartner(null);
        setSelectedBrand(null);
        setSelectedPromotion(null);
      }
      function handleSetSelectedRow(row)
      {
        setSelectedRow(row);
        switch(activeStep)
        {
            case 0:
                {
                    setSelectedPartner(row);
                    break;
                }
            case 1:
                {
                    setSelectedBrand(row);
                    break;
                }
            case 2:
                {
                    setSelectedPromotion(row);
                    break;
                }
            default:
                break;
        }
            
        
      }
      function getSelectedNames()
      {
        var displayStr = "";
        if(selectedPartner !== null)
            displayStr += "Selected Partner : "+selectedPartner.name;
        if(selectedBrand !== null)
            displayStr += " / Selected Brand : "+selectedBrand.name;
        if(selectedPromotion !== null)
            displayStr += " / Selected Promotion : "+selectedPromotion.name;
        return displayStr;
      }
      function getStepContent(step) {
        switch (step) {
          // case 0:
          //   return <DisplayUnitDetailsForCBForm />;
             case 0:
                return (
                //   <h2>Partner</h2>
                
                <Partner
                setSelectedRow={handleSetSelectedRow}
                setFormShown={setFormShown}
                alreadySelectedOwnRow={selectedPartner}
                />
                );
            case 1:
                return(
                    <Brand 
                    IssuingPartnerRow={selectedPartner}
                    setSelectedRow={handleSetSelectedRow}
                    setFormShown={setFormShown}
                    alreadySelectedOwnRow={selectedBrand}
                    />
                );
            case 2:
                return(
                    <Promotion
                    BrandRow={selectedBrand}
                    setSelectedRow={handleSetSelectedRow}
                    setFormShown={setFormShown}
                    alreadySelectedOwnRow={selectedPromotion}
                    />
                );
         
            default:
                return "Unknown step";
            }
        }    
    
      
    return(
         <Box sx={{borderStyle:'none',borderColor:'green' }}>
            <Typography variant='h4' style={{color:'red', textAlign:'center' }}>Issuing Partner</Typography>
             {/* <Toolbar> */}
        <Grid container xs={12} direction="column">
            <Grid item xs={12} style={{borderColor:'red',borderStyle:'none'}}>
            <ThemeProvider theme={theme}>
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    style={{height:'100px'}}
                    className={classes.stepper}>
                    {steps.map(label => (
                        <Step key={label}
                        
                        >
                        
                        <StepLabel sx={{
                            
                            "& .MuiStepLabel-horizontal  .Mui-active": {
                              color: "red",
                            }
                         }} >{label}</StepLabel>
                        </Step>
                    ))}

                </Stepper>
                </ThemeProvider>        
            </Grid>

          
            <Grid
                item
                container
                direction="row"
                xs={12}
                spacing={1}
                style={{ borderStyle: 'none', borderColor: 'blue' }}
            >
                <Grid item container xs={12} direction="column">
                    {activeStep === steps.length ? (
                        <Grid item>
                            <Typography className={classes.instructions}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            
                        </Grid>
                    ) : (
                        // <div>  {getStepContent(activeStep)}</div>
                        <Grid item container xs={12} style={{
                            borderStyle: 'none',
                            borderColor: 'green',
                            height:"300px"
                            }} direction="column" >
                            
                          
                                {getStepContent(activeStep)}
                          
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} direction="row">
                    <Paper>
                        {getSelectedNames()}
                    </Paper>
                </Grid>
                <Grid item justifyContent='center' container style={{ display:formShown?'none':''}}>
                    {/* <Paper className={classes.paper2}> */}
                    {activeStep === steps.length ? (
                        <Grid item style={{ borderStyle: 'none', borderColor: 'green'}} container direction="row">
                            
                                <Grid item style={{}}>
                                    <Button onClick={handleReset} className={classes.buttons}>
                                    Reset
                                </Button>
                                </Grid>
                        </Grid>
                            ) : (
                        <Grid item style={{ borderStyle: 'none', borderColor: '',height:'40px' }} 
                         justifyContent='center' alignItems='center'
                                             container direction="row">
                                <Grid item>
                                    <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    variant="contained"
                                    color='primary'
                                    className={classes.button}
                                    >
                                    
                                    Back
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    color="primary"
                                    className={classes.button}
                                    >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </Grid>
                                
                        </Grid>)}
                    {/* </Paper> */}
                 </Grid>
            </Grid>
        </Grid>
    </Box>
    );
}
export default IssuingPartner;