const USE_LOCAL = 1;
//"omniqpon.co.in:14013";
const serverIP ="omniqpon.co.in:14012";//"ubuntu@ec2-34-217-116-136.us-west-2.compute.amazonaws.com:14013";//':8080';//'125.99.72.27:3000';//'192.168.1.216:8080';////172.20.1.79//125.99.72.27:3000
const localIP = 'localhost:8080';
export default class UrlHelper {
  ip = '1';

  constructor() {
    if (USE_LOCAL === 0) {
      this.ip = localIP;
    } else {
      this.ip = serverIP;
    }
  }

  partnerUrl(id) {
    if (arguments.length === 0)
      return `http://${this.ip}/starcloudprnt/frontend/partners`;
    else if(arguments.length === 1)
    return `http://${this.ip}/starcloudprnt/frontend/partners?id=${id}`;
   
  }

  brandUrl(id){
    if(arguments.length === 0)
      return `http://${this.ip}/starcloudprnt/frontend/brands`;
    else if(arguments.length === 1 )
      return `http://${this.ip}/starcloudprnt/frontend/brands?id=${id}`;
  }
  
  brandUrl_GET(partnerId) {
    if (arguments.length === 1)
    return `http://${this.ip}/starcloudprnt/frontend/brands?iId=${partnerId}`;
  // else if(arguments.length === 2)
  // return `http://${this.ip}/starcloudprnt/frontend/brands?id=${id}`;
 
  }
  promotionUrl(id){
    if(arguments.length === 0)
      return `http://${this.ip}/starcloudprnt/frontend/promotion`;
    else if(arguments.length === 1 )
      return `http://${this.ip}/starcloudprnt/frontend/promotion?id=${id}`;
  }
  
  promotionUrl_GET(partnerId) {
    if (arguments.length === 1)
    return `http://${this.ip}/starcloudprnt/frontend/promotion?bId=${partnerId}`;
  // else if(arguments.length === 2)
  // return `http://${this.ip}/starcloudprnt/frontend/brands?id=${id}`;
 
  }
  tierUrl(programId){
    if(arguments.length === 1)
      return `http://${this.ip}/starcloudprnt/frontend/program/tiers?program_id=${programId}`
  }
  omniqponUrl()
  {
    return `http://${this.ip}/starcloudprnt/frontend/oqimage`;
  }
  programUrl(){
    if(arguments.length === 0)
    {
      return `http://${this.ip}/starcloudprnt/frontend/programs`;
    }
  }
}